// ... your code that runs before foundation initiation

// Fix for foundation stylesheets being picked up as "null" or "not an object",
// implementation from here: http://foundation.zurb.com/forum/posts/3189-foundation-5-orbit-slider-ie8-issue
// (function($) {
//     'use strict';

//     if (!Foundation.stylesheet) {
//         Foundation._style_element = $('<style></style>').appendTo('head')[0];
//         Foundation.stylesheet = Foundation._style_element.styleSheet;

//         if (Foundation.stylesheet) {
//             Foundation.stylesheet.cssRules = {
//                 length: 0
//             };

//             Foundation.stylesheet.insertRule = function(rule, index) {
//                 var media;
//                 var mediaMatch;
//                 var mediaRegex;
//                 var namespace;
//                 var ruleMatch;
//                 var ruleRegex;
//                 mediaRegex = /^\s*@media\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 mediaMatch = mediaRegex.exec(rule);
//                 media = '';

//                 if (mediaMatch) {
//                     media = '@media ' + mediaMatch[1] + ' ';
//                     rule = mediaMatch[2];
//                 }

//                 ruleRegex = /^\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
//                 ruleMatch = ruleRegex.exec(rule);
//                 namespace = '' + media + ruleMatch[1];
//                 rule = ruleMatch[2];

//                 return this.addRule(namespace, rule);
//             };
//         } else if (window.console) {
//             console.log('Could not fix Foundation CSS rules...');
//         }
//     }
// })(jQuery);

// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

// function HCTest() {
//     'use strict';

//     var objDiv;
//     var strColor;
//     var objFlag;

//     // reference to img element used to check if images are disabled
//     objFlag = document.getElementById('flag');
//     if (objFlag === null) {
//         return;
//     }

//     // Create a test div
//     objDiv = document.createElement('div');

//     // Set its color style to something unusual
//     objDiv.style.color = 'rgb(31,41,59)';

//     // Attach to body so we can inspect it
//     document.body.appendChild(objDiv);

//     // Use standard means if available, otherwise use the IE methods

//     strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

//     // get rid of extra spaces in result
//     // strColor = strColor.replace(/ /g,"");

//     // Delete the test DIV

//     document.body.removeChild(objDiv);

//     // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
//     // Use .offsetwidth and .readyState (for IE) to check if images are enabled
//     // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

//     if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

//         var objHead = document.getElementsByTagName('head');
//         var objCSS = objHead[0].appendChild(document.createElement('link'));
//         objCSS.rel = 'stylesheet';
//         objCSS.href = '/media/css/alt.css';
//         // objCSS.type = 'text/css';
//     }
// }

// Safely initiate foundation now
// $(document).foundation();

// the rest of your code

// $(window).load(function() {
    // 'use strict';

    // accessible image sprites
    // HCTest();
// });

// mobile-nav
(function() {

    // var mqm = window.matchMedia('(max-width: 46.3125em)');
    var mqm = window.matchMedia('(max-width: 63.9375em)');

    // get the button and menu nodes
    var button = document.querySelector('[aria-label="site"] button');
    var menu = button.nextElementSibling;

    // set initial (closed menu) states
    button.setAttribute('aria-expanded', 'false');
    button.hidden = false;
    if (mqm.matches) {
      menu.hidden = true;
    }

    $( window ).resize(function() {
        if (window.matchMedia('(min-width: 1024px)').matches) {
            menu.hidden = false;
        }
    });
    
    button.addEventListener('click', function() {
        // toggle menu visibility
        var expanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', String(!expanded));
        menu.hidden = expanded;
    });

})();


$(document).ready(function() {
    'use strict';

    // http://heydonworks.com/practical_aria_examples/#submenus
    $('.main-nav ul ul').prev('a')
      .attr('aria-haspopup', 'true');
      // .append('<span aria-hidden="true"> &#x25be;</span>');

    var showSubmenu = function(dropdown) {
      dropdown.attr('aria-hidden', 'false');
    };

    var hideSubmenu = function(dropdown) {
      dropdown.attr('aria-hidden', 'true');
    };

    $('.with-dropdowns > li > a').on('focus', function(e) {
      hideSubmenu($('[aria-label="submenu"]'));
    });

    $('[aria-haspopup]').on('click', function(e) {
      var submenu = $(this).next();
      showSubmenu(submenu);
      //$(submenu).find('li:first-child a').focus();
      return false;
    });

    $('[aria-haspopup]').hover(function() {
      showSubmenu($(this).next());
      $(this).off('click');
    });

    $('[aria-haspopup]').parents('li').mouseleave(function() {
      hideSubmenu($(this).find('[aria-label="submenu"]'));
    });


    // menu current
    $('[aria-current]').parent().addClass('current');


    // $(".site-menu").on("click", "a.has-submenu", function (e) {
    //     // e.preventDefault();
    //     $(this).parent().toggleClass("open");
    // });


    // feature.js
    if (feature.svg) {
        $('html').addClass('svg');
    }


    // hamburgers
    var $hamburger = $(".hamburger");
    $hamburger.on("click", function(e) {
      $hamburger.toggleClass("is-active");
      $('.menu').toggleClass("js-open");
      $('body').toggleClass("js-menu-open");
    });


    // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
    // bind a click event to the 'skip' link
    $(".skip-link").click(function(event){

        // strip the leading hash and declare
        // the content we're skipping to
        var skipTo="#"+this.href.split('#')[1];

        // Setting 'tabindex' to -1 takes an element out of normal
        // tab flow but allows it to be focused via javascript
        $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

            // when focus leaves this element,
            // remove the tabindex attribute
            $(this).removeAttr('tabindex');

        }).focus(); // focus on the content container
    });


    // Match Height
    $('.home .box--1, .home .box--2').matchHeight();
    //$('.noticies .box').matchHeight();
    $('.agenda .box__content').matchHeight();
    $('.allotjaments .box').matchHeight();
    $('.box--4').matchHeight();

    $(function() {
    $('.noticies .row').each(function(i, elem) {
        $(elem)
            .find('.box')   
            .matchHeight({byRow: false}); 
        });
    });


    // sliders
    $('.slider').slick({
        dots: true,
        autoplay: true,
        autoplaySpeed: 12000
    });


    // canvi svg per png
    if (!feature.svg) {
        var imgs = document.getElementsByTagName('img');
        var endsWithDotSvg = /.*\.svg$/;
        var i = 0;
        var l = imgs.length;
        for (; i !== l; ++i) {
            if (imgs[i].src.match(endsWithDotSvg)) {
                imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
            }
        }
    }


    // smooth scroll top
    if (window.matchMedia('(min-width: 64em)').matches) {
        $(window).scroll(function() {
          if ($(this).scrollTop() > 400) {
              $(".top").fadeIn();
          } else {
              $(".top").fadeOut();
          }
        });
        var headerHeight = 82; // fixed header height
        $(function() {
          $('a[href*="#"]:not([href="#"],[href="#ir"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                  if (window.matchMedia("(min-width: 64.0625em)").matches) {
                        
                        $('html, body').animate({
                          scrollTop: target.offset().top - headerHeight
                        }, 1000);
                  } else {
                        $('html, body').animate({
                          scrollTop: target.offset().top
                        }, 1000);
                  }
                return false;
              }
            }
          });
        });
    }


    /* 3. Progressive collapsibles
    -----------------------------------------------------------------------------------------
    */

    $('.collapsible h2').each(function() {

      var $this = $(this);

      // create unique id for a11y relationship

      var id = 'collapsible-' + $this.index();

      // wrap the content and make it focusable

      $this.nextUntil('h2').wrapAll('<div id="'+ id +'" aria-hidden="true">');
      var panel = $this.next();

      // Add the button inside the <h2> so both the heading and button semanics are read

      $this.wrapInner('<button aria-expanded="false" aria-controls="'+ id +'">');
      var button = $this.children('button');

      // Toggle the state properties

      button.on('click', function() {
        var state = $(this).attr('aria-expanded') === 'false' ? true : false;
        $(this).attr('aria-expanded', state);
        panel.attr('aria-hidden', !state);
      });

    });


    
    // sticky header
    if (window.matchMedia('(min-width: 64em)').matches) {
        // var lastScrollTop = 170;
        var lastScrollTop = 200;
        $(window).scroll(function(event){
            var st = $(this).scrollTop();
            if (st > lastScrollTop){
                $('body:not(.home) .main-header').addClass('sticky');
                $('body:not(.home)').addClass('sticky-header');
            } else {
                $('body:not(.home) .main-header').removeClass('sticky');
                $('body:not(.home)').removeClass('sticky-header');
            }
            // lastScrollTop = st;
        });
    }


    // toggle idiomes
    $('.lang-toggle').click(function() {
      $(this).toggleClass('close');
      $(this).next().toggleClass('is-hidden');
      return false;
    });
    $('.lang-list').mouseleave(function() {
      $(this).toggleClass('is-hidden');
    });


    // pagination
    $('.pagination li.current').prev().addClass('pag-prev');


    // header search (http://adrianroselli.com/2015/12/responsive-progressive-accessible-vanilla-search.html)
    // Get the search field by ID
    var eID = document.getElementById("s1");

    // Declare the event object and set it to null
    var eventObj = null;

    // Add the event listeners I want to track
    eID.addEventListener("focus", checkSearch, false);
    eID.addEventListener("blur", checkSearch, false);
    eID.addEventListener("keydown", checkSearch, false);
    // per ie11
    $("label[for='s1']").on('click', function(e){
      checkSearch(e);
      return false;
    });

    function checkSearch(eventObj) {
      try {
        // Get the event type from the object
        var eventType = eventObj.type
        // Map the tab key to a variable
        var TABKEY = 9;
        // Get the search input text value
        var eIDval = eID.value;
        // Get the parent element
        var eIDparent = eID.parentElement;
        // Set a class since we are on it
        eIDparent.className = "notempty";
        // Get the button
        var eIDbutton = eIDparent.getElementsByTagName('button')[0];
        // Set the button tabindex
        eIDbutton.setAttribute('tabindex', '0');
        // If the text value is not blank
        if (eIDval != '') {
          // Give it a class that will stay
          eIDparent.className = "notempty";
        }
        // If the text value is blank
        else {
          // Check if focus has left the input first
          if (eventType == 'blur') {
            // Set tabindex on button to remove from flow
            eIDbutton.setAttribute('tabindex', '-1');
            // Remove the class
            eIDparent.className = "";
          }
          // Check if user hit a key
          if (eventType == 'keydown') {
            // See if the user hit the tab key
            if (eventObj.keyCode == TABKEY) {
              // Set tabindex on button to remove from flow
              eIDbutton.setAttribute('tabindex', '-1');
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

});
